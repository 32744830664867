import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

export class AnimatedGraph extends Component {

    constructor(props) {
        super(props);
        const data = this.randomizeData();
        const dataInversed = this.randomizeDataInversed();
        this.state = {
            data: data,
            dataInversed: dataInversed,
        };
    }

    maxCols = 50;
    animationDuration = 2000;

    randomizeData() {
        const randomData = [];
        for (let i = 0; i < this.maxCols; ++i) {
            randomData.push(Math.random());
        }
        const graphData = {
            labels: randomData,
            datasets: [{
                backgroundColor: 'rgb(200,200,200)',
                hoverBackgroundColor: 'rgb(125,125,125)',
                data: randomData,
            }],
        };
        return graphData;
    }

    randomizeDataInversed() {
        const randomData = [];
        for (let i = 0; i < this.maxCols; ++i) {
            randomData.push(-Math.random());
        }
        const graphData = {
            labels: randomData,
            datasets: [{
                backgroundColor: 'rgb(200,200,200)',
                hoverBackgroundColor: 'rgb(125,125,125)',
                data: randomData,
            }],
        };
        return graphData;
    }

    options = {
        animation: {
            duration: this.animationDuration,
            easing: 'linear',
        },
        scales: {
            xAxes: [{
                display: false,
            }],
            yAxes: [{
                display: false,
            }],
        },
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false,
        },
        title: { display: false },
    };

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ data: this.randomizeData(), dataInversed: this.randomizeDataInversed() }), this.animationDuration);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <>
                <div className='fixed-top' style={{ zIndex: -1 }}>
                    <Bar height={30} data={this.state.dataInversed} options={this.options} />
                </div>
                <div className='fixed-bottom' style={{ zIndex: -1 }}>
                    <Bar height={30} data={this.state.data} options={this.options} />
                </div>
            </>
        );
    }
}

export default AnimatedGraph;
