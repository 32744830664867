import Link from '@material-ui/core/Link';

import React, { Component } from 'react';

import ReactMarkdown from 'react-markdown';

export class Markdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            readme: '',
        };
    }

    componentDidMount() {
        fetch(this.props.source).then(async (response) => {
            this.setState({ readme: await response.text() });
        });
    }

    renderImage(props) {
        // eslint-disable-next-line jsx-a11y/alt-text
        return <img {...props} style={{ maxWidth: '100%' }} />;
    }

    renderLink(props) {
        return <Link {...props} target='_blank' rel='noopener noreferrer' />;
    }

    render() {
        return (
            <div className='p-5'>
                <ReactMarkdown source={this.state.readme} renderers={{ image: this.renderImage, link: this.renderLink }} />
            </div>
        );
    }
}

export default Markdown;
