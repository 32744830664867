import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import TableChartIcon from '@material-ui/icons/TableChart';

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';


function ListItemLink(props) {
	const { icon, primary, to, index, selectedIndex } = props;

	return (
		<li>
			<ListItem onClick={props.handleDrawerClose} selected={selectedIndex === index} to={to} button component={RouterLink}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
}

ListItemLink.propTypes = {
	icon: PropTypes.element,
	primary: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

export function MainListItems(props) {
	return (
		<div>
			<ListItemLink index={0} {...props} to='/' primary='Home' icon={<TableChartIcon />} />
			<ListItemLink index={1} {...props} to='/About' primary='About' icon={<InfoIcon />} />
		</div>
	);
}
