import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { withTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React, { Component } from 'react';

import SwipeableViews from 'react-swipeable-views';

import Chartzone from '../Chartzone';
import Dropzone from '../Dropzone';
import Markdown from '../Markdown';
import Navigation from '../Navigation';
import Report from '../Report';
import TableSheet from '../TableSheet';


export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigateValue: 0,
            loading: false,
            fileInfo: {
                fileName: '',
                sheetName: '',
                data: [],
                plots: [],
            },
            expandPanel: true,
        };
        this.setNavigateValue = this.setNavigateValue.bind(this);
        this.setFileInfo = this.setFileInfo.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.handlePanel = this.handlePanel.bind(this);
    }

    setFileInfo(info) {
        this.setState({
            fileInfo: info,
            expandPanel: false,
        });
    }

    setNavigateValue(value) {
        this.setState({
            navigateValue: value,
        });
    }

    setLoading(bool) {
        this.setState({
            loading: bool,
        });
    }

    handlePanel() {
        const currentState = this.state;
        this.setState({
            expandPanel: !currentState.expandPanel,
        });
    }

    render() {
        return (
            <Container maxWidth={false} className='p-2'>
                <Dropzone setLoading={this.setLoading} fileInfo={this.state.fileInfo} setFileInfo={this.setFileInfo} />
                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.loading}>
                    <Typography variant='button' className='text-center m-3'>Loading...</Typography>
                </Dialog>
                <ExpansionPanel expanded={this.state.expandPanel} className='p-0 mt-2'>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={this.handlePanel}
                    >
                        {this.state.expandPanel ? '' : 'Quickstarts'}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Markdown source='/START.md' />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Collapse in={this.state.fileInfo.data.length > 0}>
                    <Navigation className='fixed-bottom' style={{ position: 'absolute', bottom: 0 }} navigateValue={this.state.navigateValue} setNavigateValue={this.setNavigateValue} />
                    <SwipeableViews
                        index={this.state.navigateValue}
                        animateHeight={true}
                    >
                        <div>
                            <TableSheet theme={this.props.theme} fileInfo={this.state.fileInfo} />
                        </div>
                        <div>
                            <Report fileInfo={this.state.fileInfo} />
                        </div>
                        <div>
                            <Chartzone setLoading={this.setLoading} fileInfo={this.state.fileInfo} />
                        </div>
                    </SwipeableViews>
                </Collapse>
            </Container>
        );
    }
}

export default withTheme(Home);