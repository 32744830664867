import PDFObject from 'pdfobject';

import jsPDF from 'jspdf';

import Chart from 'chart.js';

import Plotly from 'plotly.js-dist';

import React, { Component } from 'react';

import 'jspdf-autotable';

export class Report extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.fileInfo !== nextProps.fileInfo;
    }

    /**
     *
     * @param {jsPDF} doc PDF document object
     * @param {Object} pos position { x, y }
     * @param {number} amount amount of Y to increase
     * @param {number} height height of the next block (if next block exceeds the page's height go to new page)
     */
    increasePosY(doc, pos, amount, height = 0) {
        pos.Y += amount;
        // Test if position is out of paper border
        if (pos.Y + height > 280) {
            // Set position at the beginning of the page
            pos.Y = 20;
            doc.addPage();
        }
    }

    precision(x) {
        return Number.parseFloat(x.toPrecision(2));
    }

    async componentDidUpdate() {
        const plots = this.props.fileInfo.plots;
        const overview = plots['overview'][0];
        const doc = new jsPDF({
            orientation: 'p',
            format: 'a4',
        });
        const pos = {
            Y: 40,
        };
        doc.setFont('times');
        doc.setFontSize(40);
        doc.text('REPORT', doc.internal.pageSize.width / 2, pos.Y, null, null, 'center');
        this.increasePosY(doc, pos, 20);
        doc.setFontSize(20);
        doc.setFontType('bold');
        this.increasePosY(doc, pos, 20);
        doc.text('I. Overview', 20, pos.Y);
        doc.setFontType('normal');
        doc.setFontSize(14);
        const overviewText = doc.splitTextToSize(`${overview.number_of_features} feature${overview.number_of_features > 1 ? 's' : ''} : \n - ${overview.number_of_numerical_features} numerical feature${overview.number_of_numerical_features > 1 ? 's' : ''} : ${overview.numercial_features_list.join(', ')} \n - ${overview.number_of_categorical_features} categorical feature${overview.number_of_categorical_features > 1 ? 's' : ''} : ${overview.categorical_features_list.join(', ')} \n - ${overview.number_of_mixed_features} mixed data type feature${overview.number_of_mixed_features > 1 ? 's' : ''} : ${overview.mixed_features_list.join(', ')} \n${overview.number_of_observations} observation${overview.number_of_observations > 1 ? 's' : ''} \nMissing data \n - ${overview.number_of_missing_cells} missing cell${overview.number_of_missing_cells > 1 ? 's' : ''} (${overview.percentage_of_missing_cells.toFixed(2)}%) \n - ${overview.number_of_features_with_missing_data} feature${overview.number_of_features_with_missing_data > 1 ? 's' : ''} with missing data: ${overview.missing_features_list.join(', ')} \n `, 150);
        this.increasePosY(doc, pos, 10);
        doc.text(overviewText, 30, pos.Y);

        doc.setFontSize(20);
        doc.setFontType('bold');
        this.increasePosY(doc, pos, overviewText.length * 6);
        doc.text('II. Collerations', 20, pos.Y);
        // insert chart as image
        doc.setFontType('normal');
        doc.setFontSize(14);
        const canvas = document.createElement('CANVAS');
        const container = document.createElement('DIV');
        document.body.appendChild(container);
        container.appendChild(canvas);
        const ctx = canvas.getContext('2d');
        container.style.height = '400px';
        container.style.width = '400px';
        canvas.style.height = '400px';
        canvas.style.width = '400px';
        Chart.defaults.global.animation = 0;
        this.increasePosY(doc, pos, 10);
        if (plots['correlation']) {
            for (let i = 0; i < plots['correlation'].length; ++i) {
                const plotData = plots['correlation'][i];
                if (plotData.library === 'plotly') {
                    const gd = await Plotly.newPlot(container, plotData.data, plotData.layout);
                    const collerationGraphURI = await Plotly.toImage(gd, { height: 500, width: 500 });
                    doc.addImage(collerationGraphURI, 'PNG', 20, pos.Y, 90, 90);
                    let collerationText = '';
                    if (plots['correlation'][i]['correlated_features'].length > 0) {
                        collerationText += ' - Correlated features :\n';
                        collerationText += plots['correlation'][i].correlated_features.map(e => `(${e[0]}, ${e[1]})`).join(', ');
                    }
                    if (plots['correlation'][i]['highly_correlated_features'].length > 0) {
                        collerationText += ' - Highly correlated features :\n';
                        collerationText += plots['correlation'][i].highly_correlated_features.map(e => `(${e[0]}, ${e[1]})`).join(', ');
                    }
                    collerationText = doc.splitTextToSize(collerationText, 80);
                    doc.text(collerationText, 110, pos.Y + 10);
                    this.increasePosY(doc, pos, 90, 90);
                }
            }
        }

        doc.setFontSize(20);
        doc.setFontType('bold');
        this.increasePosY(doc, pos, 20);
        doc.text('III. Numerical features', 20, pos.Y);
        doc.setFontType('normal');
        doc.setFontSize(14);
        if (plots['histogram']) {
            for (let i = 0; i < plots['histogram'].length; ++i) {
                const plotData = plots['histogram'][i];
                if (plotData.library === 'plotly') {
                    const gd = await Plotly.newPlot(container, plotData.data, plotData.layout);
                    const histogramGraphURI = await Plotly.toImage(gd, { height: 500, width: 500 });
                    doc.addImage(histogramGraphURI, 'PNG', 20, pos.Y, 90, 90);
                    let histogramText = '';
                    histogramText += `Numerical values : ${plotData.number_of_numerical_values} (${Math.round(plotData.percentage_of_numerical_values * 100) / 100}%)\n`;
                    histogramText += `Categorical values : ${plotData.number_of_categorical_values} (${Math.round(plotData.percentage_of_categorical_values * 100) / 100}%)\n`;
                    histogramText += `Missing values : ${plotData.number_of_missing_values} (${Math.round(plotData.percentage_of_missing_values * 100) / 100}%)\n`;
                    histogramText += ` - Min: ${this.precision(plotData.min)}\n`;
                    histogramText += ` - First quartile: ${this.precision(plotData.first_quartile)}\n`;
                    histogramText += ` - Stddev: ${this.precision(plotData.stddev)}\n`;
                    histogramText += ` - Median: ${this.precision(plotData.median)}\n`;
                    histogramText += ` - Third quartile: ${this.precision(plotData.third_quartile)}\n`;
                    histogramText += ` - Max: ${this.precision(plotData.max)}\n`;
                    histogramText = doc.splitTextToSize(histogramText, 80);
                    doc.text(histogramText, 110, pos.Y + 10);
                    this.increasePosY(doc, pos, 90, 90);
                }
            }
        }

        doc.setFontSize(20);
        doc.setFontType('bold');
        this.increasePosY(doc, pos, 20);
        doc.text('IV. Categorical features', 20, pos.Y);
        doc.setFontType('normal');
        doc.setFontSize(14);
        this.increasePosY(doc, pos, 10);
        /**
         * ! Known bug: the images drawn by Chartjs will be blurry if the window is zoomed out
         * * Potential fix: replace chartjs by plotly 😦
         */
        if (plots['frequency']) {
            for (let i = 0; i < plots['frequency'].length; ++i) {
                const plotData = plots['frequency'][i];
                if (plotData.library === 'chartjs') {
                    const chart = new Chart(ctx, {
                        type: plotData.type,
                        data: plotData.data,
                        options: plotData.options,
                    });

                    doc.addImage(canvas.toDataURL('image/png', 1.0), 'PNG', 20, pos.Y, 80, 80);
                    // doc.addImage(chart.toBase64Image(), 'PNG', 20, pos.Y, 80, 80);
                    let numericalText = '';
                    numericalText += `Numerical values : ${plotData.number_of_numerical_values} (${Math.round(plotData.percentage_of_numerical_values * 100) / 100}%)\n`;
                    numericalText += `Categorical values : ${plotData.number_of_categorical_values} (${Math.round(plotData.percentage_of_categorical_values * 100) / 100}%)\n`;
                    numericalText += `Missing values : ${plotData.number_of_missing_values} (${Math.round(plotData.percentage_of_missing_values * 100) / 100}%)\n`;
                    numericalText += ` - ${plotData.categories.length} categor${plotData.categories.length > 1 ? 'ies' : 'y'} : ${plotData.categories.join(', ')}\n`;
                    numericalText = doc.splitTextToSize(numericalText, 80);
                    doc.text(numericalText, 110, pos.Y + 10);
                    this.increasePosY(doc, pos, 90, 90);
                    chart.destroy();
                }
            }
        }
        if (plots['information feature']) {
            for (let i = 0; i < plots['information feature'].length; ++i) {
                const plotData = plots['information feature'][i];
                let informationText = '';
                informationText += `Numerical values : ${plotData.number_of_numerical_values} (${Math.round(plotData.percentage_of_numerical_values * 100) / 100}%)\n`;
                informationText += `Categorical values : ${plotData.number_of_categorical_values} (${Math.round(plotData.percentage_of_categorical_values * 100) / 100}%)\n`;
                informationText += `Missing values : ${plotData.number_of_missing_values} (${Math.round(plotData.percentage_of_missing_values * 100) / 100}%)\n`;
                informationText = doc.splitTextToSize(informationText, 80);
                doc.text(informationText, 20, pos.Y + 10);
                this.increasePosY(doc, pos, 30, 30);
                if (plotData.popular_values.length > 0) {
                    doc.text('Popular values :', 20, pos.Y);
                    this.increasePosY(doc, pos, 10);
                    doc.autoTable({
                        head: [Object.keys(plotData.popular_values[0])],
                        body: plotData.popular_values.map(element => {
                            const result = [];
                            for (const key in element) {
                                if (key === 'percentage') {
                                    result.push(`${Math.round(element[key] * 100) / 100}%`);
                                }
                                else {
                                    result.push(element[key]);
                                }
                            }
                            return result;
                        }),
                        startY: pos.Y,
                    });
                }
                this.increasePosY(doc, pos, plotData.popular_values.length * 10);
            }
        }
        const pdfBlob = doc.output('bloburi');
        PDFObject.embed(pdfBlob, '#report', { pdfOpenParams: { zoom: 150 } });
        document.body.removeChild(container);
    }

    render() {
        return (
            <div className='overflow-auto d-flex justify-content-center' >
                <div id='report' className='m-2' style={{ width: '90%', height: '90vh' }} />
            </div>
        );
    }
}

export default Report;
