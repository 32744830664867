import { bin_width, Pearson_R, Correlation_ratio, Thiel_U, Cramer_V } from './statistics';
import { mergeFeatures, getColors} from './miscellaneous';
import { MAXIMUM_BAR_COUNT, FONT_COLOR, MAXIMUM_POINT_COUNT_SWARM, MAXIMUM_POINT_COUNT_SCATTER, PLOTLY_SWARMPLOT_JITTER, PLOTLY_SWARMPLOT_POINTPOS, MAXIMUM_CATEGORIES_CORRELATION_COMPUTATION, CORRELATED_THRESHOLD, HIGHLY_CORRELATED_THRESHOLD } from './constant';
import { plotScatter2D, plotSwarm } from './chartjs-plots';

export let plotHistogramPlotly = function (feature) {
    let dataXaxis = feature.title === null ? "feature" : feature.title;
    let dataTitle = "Histogram of " + dataXaxis;

    let valueArray = [];
    for (let i = 0; i < feature.data.length; i++) {
        valueArray.push(feature.data[i][1]);
    }
    let minValue = Math.min(...valueArray);
    let maxValue = Math.max(...valueArray);
    let binWidth = Math.max(bin_width(valueArray), (maxValue - minValue) / MAXIMUM_BAR_COUNT);

    return {
        title: feature.title === null ? "feature" : feature.title,
        library: "plotly",
        chart_category: "histogram",
        recommended: false,
        data: [
            {
                x: valueArray, 
                autobinx: false, 
                marker: {
                    color: getColors()[0]
                }, 
                name: dataXaxis, 
                type: "histogram", 
                xbins: { 
                    size: binWidth, 
                    start: minValue
                }
            }
        ],
        layout: {
            bargap: 0.05,  
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            }, 
            xaxis: {
                title: {
                    text: dataXaxis,
                    font: {
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        size: 12,
                        color: FONT_COLOR
                    }
                },
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                title: {
                    text: "Count",
                    font: {
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        size: 12,
                        color: FONT_COLOR
                    }
                },
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        },
        number_of_numerical_values: feature.numericalCount,
        percentage_of_numerical_values: feature.numericalCount / feature.totalCount * 100,
        number_of_categorical_values: feature.categoricalCount,
        percentage_of_categorical_values: feature.categoricalCount / feature.totalCount * 100,
        number_of_missing_values: feature.missingCount,
        percentage_of_missing_values: feature.missingCount / feature.totalCount * 100,
        min: feature.minValue,
        first_quartile: feature.q1,
        median: feature.median,
        third_quartile: feature.q3,
        max: feature.maxValue,
        mean: feature.mean,
        stddev: feature.standardDeviation
    }
}

export let plotBox = function (feature1, feature2){
    //console.log(feature1.title);
    //console.log(feature2.title);

    let dataTitle = "Box plot of 2 features";
    if (feature1.title !== null && feature2.title !== null) 
        dataTitle = "Box plot of " + feature1.title + " vs " + feature2.title;

    let mergedData = mergeFeatures([feature1, feature2]);

    if (mergedData.data.length <= MAXIMUM_POINT_COUNT_SWARM) return plotSwarm(feature1, feature2);
    //console.log(mergedData);

    let datasetLabels = [];

    // Get unique values
    for (let i = 0; i < mergedData.data.length; i++)
        if (!datasetLabels.includes(mergedData.data[i][1])) {
            datasetLabels.push(mergedData.data[i][1]);
            if (datasetLabels.length > MAXIMUM_BAR_COUNT) return null;
        } 
    
    datasetLabels.sort();

    //console.log("Check point 1 reached!");

    // Generate colors
    let colors = getColors(datasetLabels.length);

    //console.log("Check point 2 reached!");
    
    // Generate dataset
    let dataset = [];
    for (let i = 0; i < datasetLabels.length; i++) {
        dataset.push({
            y: [],
            boxpoints: mergedData.data.length <= MAXIMUM_POINT_COUNT_SWARM ? 'all' : 'Outliers',
            jitter: PLOTLY_SWARMPLOT_JITTER,
            pointpos: mergedData.data.length <= MAXIMUM_POINT_COUNT_SWARM ? PLOTLY_SWARMPLOT_POINTPOS : 0,
            type: 'box',
            name: datasetLabels[i].toString(),
            marker: {
                color: colors[i]
            },
        });
    }

    //console.log("Check point 3 reached!");

    for (let i = 0; i < mergedData.data.length; i++)
        dataset[datasetLabels.indexOf(mergedData.data[i][1])].y.push(mergedData.data[i][2]);

    //console.log("Check point 4 reached!");

    return {
        library: "plotly",
        chart_category: "box",
        recommended: false,
        data: dataset,
        layout: {
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            },
            xaxis: {
                title: {
                    text: feature1.title === null ? "Feature 1" : feature1.title,
                    font: {
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        size: 12,
                        color: FONT_COLOR
                    }
                },
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                title: {
                    text: feature2.title === null ? "Feature 2" : feature2.title,
                    font: {
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        size: 12,
                        color: FONT_COLOR
                    }
                },
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            showlegend: false,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        }
    }
}

export let plotDensity2D = function (feature1, feature2){
    let dataTitle = "Density plot of 2 features";
    if (feature1.title !== null && feature2.title !== null) 
        dataTitle = "Density plot of " + feature1.title + " vs " + feature2.title;

    let x = [], y = [];
    let mergedData = mergeFeatures([feature1, feature2]);

    if (mergedData.data.length <= MAXIMUM_POINT_COUNT_SCATTER) return plotScatter2D(feature1, feature2);

    for (let i = 0; i < mergedData.data.length; i++){
        x.push(mergedData.data[i][1]);
        y.push(mergedData.data[i][2]);
    }

    let color = getColors()[0];
    
    let dataset = [
        {
            x: x,
            y: y,
            ncontours: 20,
            colorscale: [
                ['0.0', 'rgba(0, 0, 0, 0)'],
                ['1.0', color]
            ],
            line: {
                color: color
            },
            reversescale: false,
            showscale: false,
            type: 'histogram2dcontour'
        }
    ];

    return {
        library: "plotly",
        chart_category: "density",
        recommended: false,
        data: dataset,
        layout: {
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            },
            xaxis: {
                title: {
                    text: feature1.title === null ? "Feature 1" : feature1.title,
                    font: {
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        size: 12,
                        color: FONT_COLOR
                    }
                },
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                title: {
                    text: feature2.title === null ? "Feature 2" : feature2.title,
                    font: {
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        size: 12,
                        color: FONT_COLOR
                    }
                },
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        }
    }
}

export let plotPearsonR = function (features){
    let dataTitle = "Correlation matrix using Pearson's R";
    let dataId = [];
    let dataAxis = [];
    let dataZ = [];
    let correlated_features = [];
    let highly_correlated_features = [];

    for (let i = 0; i < features.length; i++)
        if (features[i].type === "Numerical"){
            dataId.push(i);
            dataAxis.push(features[i].title);
            dataZ.push([]);
        }

    if (dataId.length <= 1) return null;

    for (let i = 0; i < dataId.length; i++)
        for (let j = 0; j < dataId.length; j++)
            dataZ[i].push(null);

    for (let i = 0; i < dataId.length; i++){
        dataZ[i][i] = 1;
        for (let j = i + 1; j < dataId.length; j++){
            let x = [], y = [];
            let mergedData = mergeFeatures([features[dataId[i]], features[dataId[j]]]);

            for (let k = 0; k < mergedData.data.length; k++){
                x.push(mergedData.data[k][1]);
                y.push(mergedData.data[k][2]);
            }

            //console.log(x);
            //console.log(y);
            
            let PR = Pearson_R(x, y);
            //console.log(PR);

            dataZ[i][j] = PR; 
            dataZ[j][i] = PR;

            if (Math.abs(PR) > HIGHLY_CORRELATED_THRESHOLD){
                highly_correlated_features.push([dataAxis[j], dataAxis[i]]);
            } else if (Math.abs(PR) > CORRELATED_THRESHOLD){
                correlated_features.push([dataAxis[j], dataAxis[i]]);
            }
        }   
    }

    let dataset = [
        {
            z: dataZ,
            x: dataAxis,
            y: dataAxis,
            type: 'heatmap',
            hoverongaps: false,
            colorbar: {
                bordercolor: FONT_COLOR,
                tickcolor: FONT_COLOR,
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                }
            }
        }
    ];
      
    return {
        library: "plotly",
        chart_category: "correlation",
        recommended: false,
        data: dataset,
        layout: {
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            },
            xaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        },
        correlated_features: correlated_features,
        highly_correlated_features: highly_correlated_features
    }
}

export let plotCorrelationRatio = function (features){
    let dataTitle = "Correlation matrix using Correlation Ratio";
    let dataIdX = [];
    let dataIdY = [];
    let dataAxisX = [];
    let dataAxisY = [];
    let dataZ = [];
    let correlated_features = [];
    let highly_correlated_features = [];

    for (let i = 0; i < features.length; i++)
        if (features[i].type === "Categorical" && 
                features[i].categories.length <= MAXIMUM_CATEGORIES_CORRELATION_COMPUTATION){
            dataIdX.push(i);
            dataAxisX.push(features[i].title);
        } else if (features[i].type === "Numerical"){
            dataIdY.push(i);
            dataAxisY.push(features[i].title);
            dataZ.push([]);
        }
    
    if (dataIdX.length === 0 || dataIdY.length === 0) return null;

    for (let i = 0; i < dataIdX.length; i++)
        for (let j = 0; j < dataIdY.length; j++)
            dataZ[i].push(null);

    for (let i = 0; i < dataIdX.length; i++){
        for (let j = 0; j < dataIdY.length; j++){
            let x = [], y = [];
            let mergedData = mergeFeatures([features[dataIdX[i]], features[dataIdY[j]]]);

            for (let k = 0; k < mergedData.data.length; k++){
                x.push(mergedData.data[k][1]);
                y.push(mergedData.data[k][2]);
            }

            //console.log(x);
            //console.log(y);
            
            let CR = Correlation_ratio(x, y);
            //console.log(dataAxisX[i], dataAxisY[j], CR);

            dataZ[j][i] = CR;
            
            if (Math.abs(CR) > HIGHLY_CORRELATED_THRESHOLD){
                highly_correlated_features.push([dataAxisX[i], dataAxisY[j]]);
            } else if (Math.abs(CR) > CORRELATED_THRESHOLD){
                correlated_features.push([dataAxisX[i], dataAxisY[j]]);
            } 
        }   
    }

    let dataset = [
        {
            z: dataZ,
            x: dataAxisX,
            y: dataAxisY,
            type: 'heatmap',
            hoverongaps: false,
            colorbar: {
                bordercolor: FONT_COLOR,
                tickcolor: FONT_COLOR,
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                }
            }
        }
    ];
      
    return {
        library: "plotly",
        chart_category: "correlation",
        recommended: false,
        data: dataset,
        layout: {
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            },
            xaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        },
        correlated_features: correlated_features,
        highly_correlated_features: highly_correlated_features
    }
}

export let plotTheilU = function (features){
    let dataTitle = "Correlation matrix using Theil's U";
    let dataId = [];
    let dataAxis = [];
    let dataZ = [];
    let correlated_features = [];
    let highly_correlated_features = [];

    for (let i = 0; i < features.length; i++)
        if (features[i].type === "Categorical" && 
                features[i].categories.length <= MAXIMUM_CATEGORIES_CORRELATION_COMPUTATION){
            dataId.push(i);
            dataAxis.push(features[i].title);
            dataZ.push([]);
        }
    
    if (dataId.length <= 1) return null;
    
    for (let i = 0; i < dataId.length; i++)
        for (let j = 0; j < dataId.length; j++)
            dataZ[i].push(null);

    for (let i = 0; i < dataId.length; i++){
        dataZ[i][i] = 1;
        for (let j = 0; j < dataId.length; j++){
            let x = [], y = [];
            let mergedData = mergeFeatures([features[dataId[i]], features[dataId[j]]]);

            for (let k = 0; k < mergedData.data.length; k++){
                x.push(mergedData.data[k][1]);
                y.push(mergedData.data[k][2]);
            }

            //console.log(x);
            //console.log(y);
            
            let TU = Thiel_U(x, y);
            //console.log(dataAxis[i], dataAxis[j], TU);

            dataZ[j][i] = TU; 

            if (i !== j){
                if (Math.abs(TU) > HIGHLY_CORRELATED_THRESHOLD){
                    highly_correlated_features.push([dataAxis[i], dataAxis[j]]);
                } else if (Math.abs(TU) > CORRELATED_THRESHOLD){
                    correlated_features.push([dataAxis[i], dataAxis[j]]);
                }
            } 
        }   
    }

    let dataset = [
        {
            z: dataZ,
            x: dataAxis,
            y: dataAxis,
            type: 'heatmap',
            hoverongaps: false,
            colorbar: {
                bordercolor: FONT_COLOR,
                tickcolor: FONT_COLOR,
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                }
            }
        }
    ];
      
    return {
        library: "plotly",
        chart_category: "correlation",
        recommended: false,
        data: dataset,
        layout: {
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            },
            xaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        },
        correlated_features: correlated_features,
        highly_correlated_features: highly_correlated_features
    }
}

export let plotCramerV = function (features){
// Need to fix bugs in CramerV function

    let dataTitle = "Correlation matrix using Cramer's V";
    let dataId = [];
    let dataAxis = [];
    let dataZ = [];
    let correlated_features = [];
    let highly_correlated_features = [];

    for (let i = 0; i < features.length; i++)
        if (features[i].type === "Categorical" && 
                features[i].categories.length <= MAXIMUM_CATEGORIES_CORRELATION_COMPUTATION){
            dataId.push(i);
            dataAxis.push(features[i].title);
            dataZ.push([]);
        }

    if (dataId.length <= 1) return null;

    for (let i = 0; i < dataId.length; i++)
        for (let j = 0; j < dataId.length; j++)
            dataZ[i].push(null);

    for (let i = 0; i < dataId.length; i++){
        dataZ[i][i] = 1;
        for (let j = i+1; j < dataId.length; j++){
            let x = [], y = [];
            let mergedData = mergeFeatures([features[dataId[i]], features[dataId[j]]]);

            for (let k = 0; k < mergedData.data.length; k++){
                x.push(mergedData.data[k][1]);
                y.push(mergedData.data[k][2]);
            }

            //console.log(x);
            //console.log(y);
        
            let CV = Cramer_V(x, y);
            //console.log(dataAxis[i], dataAxis[j], CV);

            dataZ[j][i] = CV; 
            dataZ[i][j] = CV;

            if (i < j){
                if (Math.abs(CV) > HIGHLY_CORRELATED_THRESHOLD){
                    highly_correlated_features.push([dataAxis[j], dataAxis[i]]);
                } else if (Math.abs(CV) > CORRELATED_THRESHOLD){
                    correlated_features.push([dataAxis[j], dataAxis[i]]);
                } 
            }
        }   
    }

    let dataset = [
        {
            z: dataZ,
            x: dataAxis,
            y: dataAxis,
            type: 'heatmap',
            hoverongaps: false,
            colorbar: {
                bordercolor: FONT_COLOR,
                tickcolor: FONT_COLOR,
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                }
            }
        }
    ];
      
    return {
        library: "plotly",
        chart_category: "correlation",
        recommended: false,
        data: dataset,
        layout: {
            title: {
                text: dataTitle,
                font: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 13,
                    color: FONT_COLOR
                }
            },
            xaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            }, 
            yaxis: {
                tickfont: {
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    size: 12,
                    color: FONT_COLOR
                },
                gridcolor: 'rgba(0,0,0,0.1)',
                automargin: true
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        },
        correlated_features: correlated_features,
        highly_correlated_features: highly_correlated_features
    }
}