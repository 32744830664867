import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css';

import React, { Component } from 'react';

export class TableSheet extends Component {

    processData = (data) => {
        const arrayObject = [];
        for (let i = 1; i < data.length; ++i) {
            const obj = {};
            for (let j = 0; j < data[0].length; ++j) {
                obj[data[0][j]] = data[i][j];
            }
            arrayObject.push(obj);
        }
        return arrayObject;
    }

    /* generate an array of column objects */
    make_cols = data => {
        const o = [];
        if (data[0]) {
            for (let i = 0; i < data[0].length; ++i) o.push({ headerName: data[0][i], field: data[0][i], sortable: true, filter: 'agTextColumnFilter' });
        }
        return o;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.theme !== nextProps.theme || this.props.fileInfo !== nextProps.fileInfo;
    }

    render() {
        const data = this.processData(this.props.fileInfo.data);
        return (
            <div
                id='datatable'
                style={{
                    height: '80vh',
                    width: '100%',
                }}
                className={this.props.theme.palette.type === 'light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}
            >
                <AgGridReact
                    columnDefs={this.make_cols(this.props.fileInfo.data)}
                    rowData={data}
                    modules={AllCommunityModules}
                />
            </div>
        );
    };
};

export default TableSheet;