/* 
    PREPROCESSING CONSTANTS
*/

// Maximum size of the dataset accepted for data cleaning
export const MAXIMUM_DATASET_CLEANING_SIZE = 1000000000;

// Maximum row number of the title row
export const MAXIMUM_TITLE_ROW = 5;

// Maximum percentage of null to be consider a features
export const MAXIMUM_NULL_PERCENTAGE_THRESHOLD = 0.9;

// The least percentage of numerical values of a feature to be consider a numerical feature
export const NUMERICAL_PERCENTAGE_THRESHOLD = 0.8;

// The least number of distinct numerical values of a feature to be consider a numerical feature
export const NUMERICAL_UNIQUE_VALUES_THRESHOLD = 10;

/*
    CHART CONSTANTS
*/

// Font color
export const FONT_COLOR = "rgb(160, 160, 160)";

// The maximum number of points to render scatter plot 
export const MAXIMUM_POINT_COUNT_SCATTER = 5000;

// The maximum number of points to render swarm plot 
export const MAXIMUM_POINT_COUNT_SWARM = 200;

// Maximum number of bars possible in a graph
export const MAXIMUM_BAR_COUNT = 20;

// Maximum number of bars in a multidimensional bar plot
export const MAXIMUM_MULTI_BAR_COUNT = 40;

// The maximum width of a swarm plot
export const MAXIMUM_WIDTH_SWARM_PLOT = 0.3;

// PLOTLY SWARMPLOT POINTPOS
export const PLOTLY_SWARMPLOT_POINTPOS = -1.8;

// Plotly swarmplot jittering
export const PLOTLY_SWARMPLOT_JITTER = 0.3;

// Maximum unique categories for categorical correlation computations
export const MAXIMUM_CATEGORIES_CORRELATION_COMPUTATION = 20;

/*
    REPORT CONSTANTS
*/

// Number of popular values to return
export const POPULAR_VALUES_COUNT = 10;

// Correlated threshold
export const CORRELATED_THRESHOLD = 0.3;

// Highly correlated threshold
export const HIGHLY_CORRELATED_THRESHOLD = 0.7;