import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BrightnessHighOutlinedIcon from '@material-ui/icons/BrightnessHighOutlined';
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined';

import React from 'react';

import PropTypes from 'prop-types';

import styles from './LightSwitch.module.css';

export default function LightSwitch(props) {
    return (
        <>
            <Typography variant='button' className='m-1'>{props.darkmode ? 'DARK MODE' : 'LIGHT MODE'}</Typography>
            <IconButton color='primary' onClick={props.changeTheme}>
                {props.darkmode ? <Brightness2OutlinedIcon className={styles.spinY} /> : <BrightnessHighOutlinedIcon className={styles.spinX} />}
            </IconButton>
        </>
    );
}

LightSwitch.propTypes = {
    darkmode: PropTypes.bool.isRequired,
    changeTheme: PropTypes.func.isRequired,
};