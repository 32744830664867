import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableChartIcon from '@material-ui/icons/TableChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';

import React, { Component } from 'react';


export class Navigation extends Component {

    render() {
        return (
            <>
                <Tabs
                    className='mb-3'
                    variant='fullWidth'
                    indicatorColor='primary'
                    textColor='primary'
                    centered
                    value={this.props.navigateValue}
                    onChange={(event, newValue) => {
                        if (this.props.navigateValue !== newValue) this.props.setNavigateValue(newValue);
                    }}
                >
                    {/* <Tab label="Options" icon={<SettingsIcon />} /> */}
                    <Tab label="Table" value={0} icon={<TableChartIcon />} />
                    <Tab label="Report" value={1} icon={<AssignmentIcon />} />
                    <Tab label="Charts" value={2} icon={<BarChartIcon />} />
                </Tabs>
            </>
        );
    }
}

export default Navigation;
