export let standard_deviation = function (x) {
    //Calculate mean of elements in array x
    let sum_x = 0;
    let mean_x = 0;
    for (let i = 0; i < x.length; i++) {
        sum_x += x[i];
    }
    mean_x = sum_x / x.length;

    // Calculate standard deviation through variance
    let variance = 0;
    for (let i = 0; i < x.length; i++) {
        variance += Math.pow(x[i] - mean_x, 2) / x.length
    }
    let standard_deviation = Math.sqrt(variance);

    return standard_deviation
}

export let skewness = function (x) {
    // Calculate mean of elements in array x
    let sum_x = 0;
    let mean_x = 0;
    for (let i = 0; i < x.length; i++) {
        sum_x += x[i];
    }
    mean_x = sum_x / x.length;

    // Calculate numerator and denominator of skewness
    let skewness_numerator = 0;
    for (let i = 0; i < x.length; i++) {
        skewness_numerator += Math.pow(x[i] - mean_x, 3);
    }
    let skewness_denominator = (x.length - 1) * Math.pow(standard_deviation(x), 3);

    // Calculate skewness
    let skewness = skewness_numerator / skewness_denominator;

    return skewness
}

export let kurtosis = function (x) {
    // Calculate mean of elements in array x
    let sum_x = 0;
    let mean_x = 0;
    for (let i = 0; i < x.length; i++) {
        sum_x += x[i];
    }
    mean_x = sum_x / x.length;

    // Calculate numerator and denominator of kurtosis
    let kurtosis_numerator = 0;
    let sum_square = 0; // additional variable to calculate denominator 
    for (let i = 0; i < x.length; i++) {
        kurtosis_numerator += Math.pow(x[i] - mean_x, 4);
        sum_square += Math.pow(x[i] - mean_x, 2);
    }
    let kurtosis_denominator = Math.pow(sum_square, 2);

    // Calculate kurtosis
    let kurtosis = kurtosis_numerator / kurtosis_denominator;

    return kurtosis
}

export let sem = function (x) {
    // Calculate standard deviation of the mean of the array x

    // Calculate standard deviation of the mean
    let sem = standard_deviation(x) / x.length;

    return sem
}

export let Pearson_R = function (x, y) {
    // Calculate length of array 
    let array_length = x.length = y.length;

    // Arrays of elements (x_i)^2, (y_i)^2 and x_iy_i 
    let x2 = [];
    let y2 = [];
    let xy = [];

    for (let i = 0; i < array_length; i++) {
        x2.push(Math.pow(x[i], 2));
        y2.push(Math.pow(y[i], 2));
        xy.push(x[i] * y[i]);
    }

    // Sum of all elements in array x, y, x2, y2 and xy
    let sum_x = 0;
    let sum_y = 0;
    let sum_x2 = 0
    let sum_y2 = 0;
    let sum_xy = 0;

    for (let i = 0; i < array_length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_x2 += x2[i];
        sum_y2 += y2[i];
        sum_xy += xy[i];
    }

    // Calculate numerator and denominator in the formula of Pearson's R
    let R_numerator = array_length * sum_xy - sum_x * sum_y;
    let R_denominator = Math.sqrt(array_length * sum_x2 - Math.pow(sum_x, 2)) * Math.sqrt(array_length * sum_y2 - Math.pow(sum_y, 2));

    // Calculate Pearson's R
    let Pearson_R = R_numerator / R_denominator;

    return Pearson_R
}

export let Kendall_rank = function (x, y) {
    // Calculate length of array
    let array_length = x.length = y.length;

    // Initiate concordant pairs and discordant pairs
    let concordant_pairs = 0;
    let discordant_pairs = 0;

    // Calculate number of concordant pairs and discordant pairs
    for (let i = 0; i < array_length; i++) {
        for (let j = i + 1; j < array_length; j++) {
            if ((x[i] > x[j] && y[i] > y[j]) || (x[i] < x[j] && y[i] < y[j])) {
                concordant_pairs += 1;
            } else if ((x[i] > x[j] && y[i] < y[j]) || (x[i] < x[j] && y[i] > y[j])) {
                discordant_pairs += 1;
            }
        }
    }

    // Calculate numerator and denominator of Kendall rank correlation coefficient 
    let Kendall_rank_numenator = concordant_pairs - discordant_pairs;
    let Kendall_rank_denominator = array_length * (array_length - 1) / 2;

    // Calculate Kendall rank correlation coefficient
    let Kendall_rank = Kendall_rank_numenator / Kendall_rank_denominator;

    return Kendall_rank
}

export let Spearman_rank = function (x, y) {
    // Calculate the rank of x and y 
    let sorted_x = x.slice().sort(function (a, b) { return b - a });
    let ranks_x = x.slice().map(function (v) { return sorted_x.indexOf(v) + 1 });

    let sorted_y = y.slice().sort(function (a, b) { return b - a });
    let ranks_y = y.slice().map(function (v) { return sorted_y.indexOf(v) + 1 });

    // Calculate Spearman rank correlation coefficient 
    Spearman_rank = Pearson_R(ranks_x, ranks_y);

    return Spearman_rank
}

export let Cramer_V = function (x, y) {
    // Note that for Cramer's V, x and y are both categorical data

    // Calculate the length of array
    let array_length = x.length = y.length;

    // Extract unique values of categorical-value array x and y
    let unique_values_x = new Array(0);
    let unique_values_y = new Array(0);
    for (let i = 0; i < array_length; i++) {
        if (unique_values_x.includes(x[i]) === false) {
            unique_values_x.push(x[i]);
        }
    }
    for (let i = 0; i < array_length; i++) {
        if (unique_values_y.includes(y[i]) === false) {
            unique_values_y.push(y[i]);
        }
    }

    // Count the appearance of each unique values in array x and y 
    let appearances_in_x = new Array(unique_values_x.length).fill(0);
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < array_length; j++) {
            if (unique_values_x[i] === x[j]) {
                appearances_in_x[i] += 1;
            }
        }
    }
    let appearances_in_y = new Array(unique_values_y.length).fill(0);
    for (let i = 0; i < unique_values_y.length; i++) {
        for (let j = 0; j < array_length; j++) {
            if (unique_values_y[i] === y[j]) {
                appearances_in_y[i] += 1;
            }
        }
    }

    // Count the appearance of combined unique values of x and y and return as a unique_values_x * unique_values_y matrix
    let appearances_by_x_and_y = new Array(unique_values_x.length);
    for (let i = 0; i < unique_values_x.length; i++) {
        appearances_by_x_and_y[i] = new Array(unique_values_y.length).fill(0);
    }
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < unique_values_y.length; j++) {
            for (let k = 0; k < array_length; k++) {
                if (x[k] === unique_values_x[i] && y[k] === unique_values_y[j]) {
                    appearances_by_x_and_y[i][j] += 1;
                }
            }
        }
    }

    // Calculate chi-squared
    let chi_squared = 0;
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < unique_values_y.length; j++) {
            let chi_squared_numerator = Math.pow(appearances_by_x_and_y[i][j] - appearances_in_x[i] * appearances_in_y[j] / array_length, 2);
            let chi_squared_denominator = appearances_in_x[i] * appearances_in_y[j] / array_length;
            chi_squared += chi_squared_numerator / chi_squared_denominator;
        }
    }

    // Calculate the numerator and denominator of Cramer's V
    let Cramer_V_numerator = chi_squared / array_length;
    let Cramer_V_denominator = Math.min(unique_values_x.length - 1, unique_values_y.length - 1);

    // Calculate Cramer's V
    Cramer_V = Math.sqrt(Cramer_V_numerator / Cramer_V_denominator);

    return Cramer_V
}

export let Thiel_U = function (x, y) {
    // Note that again, for Thiel's U, x and y are both categorical data

    // Calculate the length of array 
    let array_length = x.length = y.length;

    // Extract unique values of categorical-value array x and y
    let unique_values_x = new Array(0);
    for (let i = 0; i < array_length; i++) {
        if (unique_values_x.includes(x[i]) === false) {
            unique_values_x.push(x[i]);
        }
    }
    let unique_values_y = new Array(0);
    for (let i = 0; i < array_length; i++) {
        if (unique_values_y.includes(y[i]) === false) {
            unique_values_y.push(y[i]);
        }
    }

    // Count the appearance of each unique values in array x and y 
    let appearances_in_x = new Array(unique_values_x.length).fill(0);
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < array_length; j++) {
            if (unique_values_x[i] === x[j]) {
                appearances_in_x[i] += 1;
            }
        }
    }
    let appearances_in_y = new Array(unique_values_y.length).fill(0);
    for (let i = 0; i < unique_values_y.length; i++) {
        for (let j = 0; j < array_length; j++) {
            if (unique_values_y[i] === y[j]) {
                appearances_in_y[i] += 1;
            }
        }
    }

    // Count the appearance of combined unique values of x and y and return as a unique_values_x * unique_values_y matrix
    let appearances_by_x_and_y = new Array(unique_values_x.length);
    for (let i = 0; i < unique_values_x.length; i++) {
        appearances_by_x_and_y[i] = new Array(unique_values_y.length).fill(0);
    }
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < unique_values_y.length; j++) {
            for (let k = 0; k < array_length; k++) {
                if (x[k] === unique_values_x[i] && y[k] === unique_values_y[j]) {
                    appearances_by_x_and_y[i][j] += 1;
                }
            }
        }
    }

    // Calculate the probability of appearance of each unique value in array x, y, x and y 
    let probability_in_x = new Array(unique_values_x.length);
    for (let i = 0; i < unique_values_x.length; i++) {
        probability_in_x[i] = appearances_in_x[i] / array_length;
    }
    let probability_in_y = new Array(unique_values_y.length);
    for (let i = 0; i < unique_values_y.length; i++) {
        probability_in_y[i] = appearances_in_y[i] / array_length;
    }

    let probability_in_x_and_y = new Array(unique_values_x.length);
    for (let i = 0; i < unique_values_x.length; i++) {
        probability_in_x_and_y[i] = new Array(unique_values_y.length);
    }
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < unique_values_y.length; j++) {
            probability_in_x_and_y[i][j] = appearances_by_x_and_y[i][j] / array_length;
        }
    }

    // Define the log base x function
    function getBaseLog(x, y) {
        return Math.log(y) / Math.log(x);
    }

    // Calculate entropy
    let entropy_x = 0;
    for (let i = 0; i < unique_values_x.length; i++) {
        entropy_x -= probability_in_x[i] * getBaseLog(2, probability_in_x[i]);
    }

    // Calculate contritional entropy
    let conditional_entropy = 0;
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < unique_values_y.length; j++) {
            if (probability_in_x_and_y[i][j] !== 0) {
                conditional_entropy -= probability_in_x_and_y[i][j] * getBaseLog(2, (probability_in_x_and_y[i][j] / probability_in_y[j]));
            }
        }
    }

    // Calculate Thiel's U
    let Thiel_U = (entropy_x - conditional_entropy) / entropy_x;

    return Thiel_U
}

export let Correlation_ratio = function (x, y) {
    // Note that in this case, we assume that x is the categorical-value array, y is the numerical-value array

    // Calculate the length of array
    let array_length = x.length = y.length;

    // Extract unique values of categorical-value array x
    let unique_values_x = new Array(0);
    for (let i = 0; i < array_length; i++) {
        if (unique_values_x.includes(x[i]) === false) {
            unique_values_x.push(x[i]);
        }
    }

    // Count the appearance of each unique values in array x
    let appearances_in_x = new Array(unique_values_x.length).fill(0);
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < array_length; j++) {
            if (unique_values_x[i] === x[j]) {
                appearances_in_x[i] += 1;
            }
        }
    }

    // Calculate the mean of sum of all elements of y 
    let sum_y = 0;
    for (let i = 0; i < array_length; i++) {
        sum_y += y[i];
    }
    let mean_y = sum_y / array_length;

    // Calculate the mean of sum of elements by categoricals in y
    let sum_by_categories_y = new Array(unique_values_x.length).fill(0);
    let mean_by_categories_y = new Array(unique_values_x.length).fill(0);
    for (let i = 0; i < unique_values_x.length; i++) {
        for (let j = 0; j < array_length; j++) {
            if (unique_values_x[i] === x[j]) {
                sum_by_categories_y[i] += y[j];
            }
        }
        mean_by_categories_y[i] = sum_by_categories_y[i] / appearances_in_x[i];
    }

    // Calculate numercator and denominator in correlation ratio
    let Correlation_ratio_numerator = 0;
    let Correlation_ratio_denominator = 0;
    for (let i = 0; i < unique_values_x.length; i++) {
        Correlation_ratio_numerator += appearances_in_x[i] * Math.pow(mean_by_categories_y[i] - mean_y, 2);
    }
    for (let i = 0; i < array_length; i++) {
        Correlation_ratio_denominator += Math.pow(y[i] - mean_y, 2);

    }

    // Calculate the Correlation_ratio
    let Correlation_ratio = Correlation_ratio_numerator / Correlation_ratio_denominator;

    return Correlation_ratio
}

export let bin_width = function (x) {
    // Calculate IQR and bin_width using Freedman–Diaconis rule
    let interquartile_range = Quartile(x, 0.75) - Quartile(x, 0.25);
    let bin_width = 2 * interquartile_range / Math.cbrt(x.length);

    return bin_width
}

export let linear_regression = function (x, y) {
    // Here, we define y as dependent variable, under the form of a n x 1 matrix
    // x are indepedent variables, under the form of n x k 

    // Calculate matrix beta_hat of coefficients
    const new_x = x;
    for (let i = 0; i < x.length; i++) {
        new_x[i].unshift(1);
    }
    const new_x_transpose = Math.transpose(new_x);

    const beta_hat = Math.multiply(Math.multiply(Math.inv(Math.multiply(new_x_transpose, new_x)), new_x_transpose), y);

    // Calculate y_hat
    const y_hat = Math.multiply(new_x, beta_hat);

    // Calculate residuals (difference between real values and estimated values)
    const residuals = Math.substract(y, y_hat);

    // Calculate sum and mean of elements in y and y_hat
    let sum_y = 0;
    let mean_y = 0;
    //let sum_y_hat = 0;
    //let mean_y_hat = 0;

    for (let i = 0; i < y.length; i++) {
        sum_y += y[i][0];
        //sum_y_hat += y_hat[i][0];
    }

    mean_y = sum_y / y.length;
    //mean_y_hat = sum_y_hat / y_hat.length;

    // Calculate R-squared and adjusted R-squared 
    let R_squared_numerator = 0;
    let R_squared_denominator = 0;

    for (let i = 0; i < y[0].length; i++) {
        R_squared_numerator += Math.pow(y_hat[i][0] - mean_y, 2);
        R_squared_denominator += Math.pow(y[i][0] - mean_y, 2);
    }

    let R_squared = R_squared_numerator / R_squared_denominator;
    let adjusted_R_squared = 1 - ((1 - Math.pow(R_squared, 2)) * (x.length - 1)) / (x.length - x[0].length - 1);

    return {
        "Coefficients": {
            "y-intercept": beta_hat[0][0],
            "variable coefficients": beta_hat[0].slice(1),
        },
        "Residuals": {
            "Minimum": Math.min(residuals[0]),
            "25th percentile": Quartile(residuals[0], 0.25),
            "50th percentile": Quartile(residuals[0], 0.5),
            "75th percentile": Quartile(residuals[0], 0.75),
            "Maximum": Math.max(residuals[0])
        },
        "Goodness_of_fit": {
            "R_squared": R_squared,
            "Adjusted_R_squared": adjusted_R_squared
        }
    }
}

export let vif = function (x, y) {
    // This function is used to calculate variance Inflation Factor with dependent variable observations x and the remaining independent variable observations y.
    // x is in the form of n x 1 matrix, y is in the form of n x k matrix.

    // Calculate linear regression of x based on y, and find r-squared of that linear regression
    let linear_regression_result = linear_regression(x, y);
    let r_squared = linear_regression_result.Goodness_of_fit.R_squared;

    // Calculate VIF
    let vif = 1 / (1 - Math.pow(r_squared, 2));

    return vif
}

export let vif_alldata = function (x) {
    // This function is used to calculate vif for a whole block of dataset x imported, based on the function vif written above.
    // Assume that x has the form of a n x k matrix, i.e n observations of k variables. Our goal is to calculate k vifs for those k variables.

    // 
    vif_alldata = new Array(x.length);

    //Calculate VIF for each variable
    for (let i = 0; i < x[0].length; i++) {
        let new_x = new Array(x.length);
        let new_y = x;
        for (let j = 0; j < x.length; j++) {
            new_x[j] = x[j][i];
            new_y[j].splice(i, 1);
        }
        vif_alldata[i] = vif(new_x, new_y);
    }

    return vif_alldata
}

function Quartile(data, q) {
    data = Array_Sort_Numbers(data);
    let pos = ((data.length) - 1) * q;
    let base = Math.floor(pos);
    let rest = pos - base;
    if ((data[base + 1] !== undefined)) {
        return data[base] + rest * (data[base + 1] - data[base]);
    } else {
        return data[base];
    }
}

function Array_Sort_Numbers(inputarray) {
    return inputarray.sort(function (a, b) {
        return a - b;
    });
}