import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import React, { Component } from 'react';

import AnimatedGraph from '../AnimatedGraph';
import Markdown from '../Markdown';

export class About extends Component {

    render() {
        return (
            <Container maxWidth='lg' className='p-5'>
                <AnimatedGraph />
                <Paper>
                    <Markdown source='/ABOUT.md' />
                </Paper>
            </Container>
        );
    }
}

export default About;
