import { NUMERICAL_PERCENTAGE_THRESHOLD, NUMERICAL_UNIQUE_VALUES_THRESHOLD, MAXIMUM_DATASET_CLEANING_SIZE, MAXIMUM_NULL_PERCENTAGE_THRESHOLD, MAXIMUM_TITLE_ROW} from "./constant";

let cmp = function (a, b){
    if (!isNaN(a) && !isNaN(b)) return a - b;
    if (!isNaN(a) && isNaN(b)) return 1;
    if (isNaN(a) && !isNaN(b)) return -1;
    if (a == null && b == null) return 0;
    if (a == null) return -1;
    if (b == null) return 1;
    if (a < b) return -1;
    if (a === b) return 0;
    return 1;
}

let cleanFeature = function(feature){
    // Decide whether a feature is numerical or categorical.
    // If it is numerical then remove all null, categorical observations.
    // If it is categorical then all null become one category.

    let numericalCount = 0, isNumerical = false, numericalData = [], missingCount = 0, totalCount = feature.data.length;
    let categories = [], categoriesCount = [], categoriesIndex = [];
    let mean = 0, mode = null, median = 0, minValue = null, maxValue = null, standardDeviation = 0, q1 = null, q3 = null;
    for (let i = 0; i < feature.data.length; i++){
        if (!isNaN(feature.data[i][1]) || feature.data[i][1] == null) {
            numericalCount += 1;
            if (feature.data[i][1] != null){
                numericalData.push(feature.data[i]);
                mean += feature.data[i][1];
            } else missingCount++;
        }
    }
    // Compute mean value
    if (numericalCount > missingCount) mean = mean / (numericalCount - missingCount);

    // Get unique values
    let tempData = [], tempCount = 0;
    for (let i = 0; i < feature.data.length; i++) tempData.push(feature.data[i][1]);
    tempData.sort((a, b) => cmp(a, b));
    //console.log(tempData);
    for (let i = 0; i < tempData.length; i++){
        if (!isNaN(tempData[i])){
            tempCount++;
            standardDeviation += (tempData[i] - mean) * (tempData[i] - mean);
            // Compute the first quantile
            if (tempCount / (numericalCount - missingCount) >= 0.25 && q1 == null) q1 = tempData[i];
            // Compute the third quantile
            if (tempCount / (numericalCount - missingCount) >= 0.75 && q3 == null) q3 = tempData[i];
            // Compute the median
            if (tempCount === Math.ceil((numericalCount - missingCount) / 2)) median += tempData[i];
            if (tempCount === Math.ceil((numericalCount - missingCount) / 2) + 1 && (numericalCount - missingCount) % 2 === 0) median = (median + tempData[i]) / 2;
            // Compute min and max values
            if (minValue == null) minValue = tempData[i];
            maxValue = tempData[i];
        }
        if (categories.length === 0 || categories[categories.length - 1] !== tempData[i]){
            categories.push(tempData[i]);
            categoriesCount.push(0);
            categoriesIndex.push(categoriesIndex.length);
        }
        categoriesCount[categoriesCount.length - 1]++;
    }
    categoriesIndex.sort((a, b) => categoriesCount[b] - categoriesCount[a]);
    // Compute mode
    for (let i = 0; i < categories.length; i++)
        if (categories[categoriesIndex[i]] != null){
            mode = categories[categoriesIndex[i]];
            break;
        }
    // Compute standard deviation
    if (numericalCount > missingCount) standardDeviation = Math.sqrt(standardDeviation / (Math.max(1, numericalCount - missingCount - 1)));
    
    // If more than certain percentage is numerical and certain number of unique values then the feature is numerical
    if (numericalCount / feature.data.length > NUMERICAL_PERCENTAGE_THRESHOLD && 
        categories.length > NUMERICAL_UNIQUE_VALUES_THRESHOLD) 
        isNumerical = true;

    if (isNumerical){
        feature.type = "Numerical"; 
        feature.data = numericalData;
        feature.mean = mean;
        feature.median = median;
        feature.q1 = q1;
        feature.q3 = q3;
        feature.minValue = minValue;
        feature.maxValue = maxValue;
        feature.standardDeviation = standardDeviation;
    } else {
        feature.type = "Categorical";
        feature.categories = categories;
        feature.mode = mode;
        feature.categoriesCount = categoriesCount;
        feature.categoriesIndex = categoriesIndex;
    }
    feature.numericalCount = numericalCount - missingCount;
    feature.categoricalCount = totalCount - numericalCount;
    feature.missingCount = missingCount;
    feature.totalCount = totalCount;
    //console.log(feature);
    return feature;
};

let cleanRows = function (data){
    let newData = [];
    for (let i = 0; i < data.length; i++){
        let cnt = 0;
        for (let j = 0; j < data[i].length; j++)
            if (data[i][j] == null) cnt++;
        if (cnt / data[i].length < MAXIMUM_NULL_PERCENTAGE_THRESHOLD){
            newData.push([]);
            for (let j = 0; j < data[i].length; j++) newData[newData.length - 1].push(data[i][j]);
        }
    }
    return newData;
}

let cleanColumns = function (data){
    let newData = [];
    for (let i = 0; i < data.length; i++) newData.push([]);
    for (let i = 0; i < data[0].length; i++){
        let cnt = 0;
        for (let j = 0; j < data.length; j++)
            if (data[j][i] == null) cnt++;
        if (cnt / data.length < MAXIMUM_NULL_PERCENTAGE_THRESHOLD){
            for (let j = 0; j < data.length; j++) newData[j].push(data[j][i]);
        }
    }
    return newData;
}

let findTitle = function (data){
    // A row is the title row if it contains only strings, 
    // no null except the first element and
    // it must be among the first rows of the dataset
    // Each title is unique and does not reoccur in the data 
    let newData = [];
    
    let i = 0;
    while (i < Math.min(MAXIMUM_TITLE_ROW, data.length)){
        let check = true;
        for (let j = 1; j < data[i].length; j++)
            if (typeof data[i][j] !== 'string'){
                check = false;
                //console.log(i, j);
                break;
            }
        if (check !== false)
            for (let j = 1; j < data[i].length; j++)
                if (check !== false)
                    for (let k = i + 1; k < data.length; k++)
                        if (data[k][j] === data[i][j]){
                            check = false;
                            //console.log(i, j, k);
                            break;
                        }
        if (check === true) break;
        i++;
    }

    if (i >= Math.min(MAXIMUM_TITLE_ROW, data.length)){
        // No titles found
        newData.push([null]);
        for (let j = 1; j < data[0].length; j++) newData[0].push("Feature " + j.toString());
        for (let j = 0; j < data.length; j++) newData.push(data[j]);
    } else {
        // Title found and it is at row i
        for (let j = i; j < data.length; j++) newData.push(data[j]);
    }
    return newData;
}

let findIndex = function (data) {
    // The data index must be unique and be the first column.
    if (data[0][0] == null) data[0][0] = "Id";

    let indexList = [];
    for (let i = 1; i < data.length; i++) indexList.push(data[i][0]);
    indexList.sort();

    for (let i = 0; i < indexList.length; i++)
        if ((i > 0 && indexList[i] === indexList[i - 1]) || indexList[i] == null) {
            // No index found
            let newData = [];
            newData.push(['Id']);
            for (let j = 0; j < data[0].length; j++) newData[0].push(data[0][j]);
            for (let j = 1; j < data.length; j++) {
                newData.push([j.toString()]);
                for (let k = 0; k < data[j].length; k++) newData[j].push(data[j][k]);
            }
            return newData;
        } 
    return data;
}

let cleanData = function (data){
    // Clean rows with excessive percentage of null
    data = cleanRows(data);
    // Clean columns with excessive percentage of null
    data = cleanColumns(data);
    // Attempt to find the title of each feature
    data = findTitle(data);
    // Attempt to find index
    data = findIndex(data);
    return data;
}

export let getFeatures = function(data){
    // Extracting features from a data source
    
    // Try to clean the dataset if the dataset is small enough
    if (data.data.length * data.data[0].length <= MAXIMUM_DATASET_CLEANING_SIZE) data.data = cleanData(data.data);

    // the first row contains titles of each feature, 
    // the first column contains indexes of each observation.
    // There should be no empty row or column.

    let featureIndex = data.data[0][0];
    let featureTitles = data.data[0];
    let features = [];

    // Count missing observations
    let number_of_observations_with_missing_data = 0;
    for (let i = 1; i < data.data.length; i++){
        let is_missing = false;
        for (let j = 1; j < featureTitles.length; j++)
            if (data.data[i][j] == null) is_missing = true;
        if (is_missing) number_of_observations_with_missing_data++;
    }
    
    for (let i = 1; i < featureTitles.length; i++){
        let feature = {type: "", title: featureTitles[i], index: featureIndex, data: []};
        for (let j = 1; j < data.data.length; j++)
            feature.data.push([data.data[j][0], data.data[j][i]]);
        feature = cleanFeature(feature);
        features.push(feature);
    }
    return [features, number_of_observations_with_missing_data];
};

export default getFeatures;