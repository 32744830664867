import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import React from 'react';

import clsx from 'clsx';

import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

import LightSwitch from '../LightSwitch';
import { MainListItems } from '../ListItems';
import Logo from '../Logo';
import ShowTheLocation from '../ShowTheLocation';
import About from '../pages/About';
import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsConditions from '../pages/TermsConditions';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		// keep right padding when drawer closed
		paddingRight: 24,
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	menuButton: {
		marginRight: 12,
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(0),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(8),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
}));

export default function Dashboard(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const [location, setLocation] = React.useState();
	const locationList = ['/', '/About'];
	const [selectedIndex, setSelectedIndex] = React.useState();
	React.useEffect(() => {
		setSelectedIndex(locationList.indexOf(location));
	}, [locationList, location]);
	return (
		<Router>
			<div className={classes.root}>
				<CssBaseline />
				<AppBar color='default' position='absolute' className={clsx(classes.appBar)}>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='open drawer'
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton)}
						>
							<MenuIcon />
						</IconButton>
						<div className={classes.title}>
							<Logo />
          				</div>
						<LightSwitch darkmode={props.darkmode} changeTheme={props.changeTheme} />
						<ShowTheLocation setLocation={setLocation} />
					</Toolbar>
				</AppBar>
				<Drawer
					variant='temporary'
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
					}}
					onClose={handleDrawerClose}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<Logo />
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<List><MainListItems handleDrawerClose={handleDrawerClose} selectedIndex={selectedIndex} /></List>
					<Divider />
					<div className='fixed-bottom p-3'>
						<NavLink onClick={handleDrawerClose} to='/Privacy-policy'>Privacy policy</NavLink>
						<br />
						<NavLink onClick={handleDrawerClose} to='/Terms-and-conditions'>Terms and conditions</NavLink>
					</div>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Switch>
						<Route path='/' exact component={Home} />
						<Route path='/About' component={About} />
						<Route path='/Privacy-policy' component={PrivacyPolicy} />
						<Route path='/Terms-and-conditions' component={TermsConditions} />
					</Switch>
				</main>
			</div>
		</Router>
	);
}
