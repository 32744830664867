import * as chartjs_plots from "./chartjs-plots";
import * as plotly_plots from "./plotly-plots";
import { getOverview } from "./miscellaneous";

export let plotFeatures = function (features, number_of_observations_with_missing_data) {
    let plots = [];

    // Overview
    try{
        let overview = getOverview(features, number_of_observations_with_missing_data);
        //console.log(overview);
        if (overview !== null) plots.push(overview);
    }catch{};

    // Correlation
    // 1. Pearson's R
    try{
        let plot = plotly_plots.plotPearsonR(features);
        if (plot !== null) plots.push(plot);
    }catch{};
    // 2. Correlation Ratio
    try{
        let plot = plotly_plots.plotCorrelationRatio(features);
        if (plot !== null) plots.push(plot);
    }catch{};
    // 3. Cramer's V // Bugs
    // try{
    //     let plot = plotly_plots.plotCramerV(features);
    //     if (plot !== null) plots.push(plot);
    // }catch{};
    // 4. Theil's U
    try{
        let plot = plotly_plots.plotTheilU(features);
        if (plot !== null) plots.push(plot);
    }catch{};

    // Start with 1-dimensional plots
    for (let i = 0; i < features.length; i++) {
        if (features[i].type === "Categorical") {
            try{
                let plot = chartjs_plots.plotFrequency(features[i]);
                if (plot !== null) plots.push(plot);
            }catch{};
        }
        else {
            try{
                let plot = plotly_plots.plotHistogramPlotly(features[i]);
                if (plot !== null) plots.push(plot);
            }catch{};
        }
    }

    // Plot 2-dimensional plots
    for (let i = 0; i < features.length; i++)
        for (let j = i + 1; j < features.length; j++)
            if (features[i].type === "Numerical" && features[j].type === "Numerical"){
                try{
                    let plot = plotly_plots.plotDensity2D(features[i], features[j]);
                    if (plot !== null) plots.push(plot);
                }catch{};
            } else if (features[i].type === "Categorical" && features[j].type === "Categorical"){
                try{
                    let plot = chartjs_plots.plotFrequency2D(features[i], features[j]);
                    if (plot !== null) plots.push(plot);
                }catch{};
            } else if (features[i].type === "Categorical" && features[j].type === "Numerical"){
                try{
                    let plot = plotly_plots.plotBox(features[i], features[j]);
                    if (plot !== null) plots.push(plot);
                }catch{};
            } else if (features[i].type === "Numerical" && features[j].type === "Categorical"){
                try{
                    let plot = plotly_plots.plotBox(features[j], features[i]);
                    if (plot !== null) plots.push(plot);
                }catch{};
            }

    return plots;
}

export default plotFeatures;