import getFeatures from './preprocess';
import plotFeatures from './plot';
import { deepCopy } from './miscellaneous';

export let getPlots = function(rawdata){
    let data = {data: []};
    deepCopy(rawdata, data.data);
    //console.log(rawdata);

    let features, number_of_observations_with_missing_data, plots;

    try{
        let ret = getFeatures(data);
        features = ret[0];
        number_of_observations_with_missing_data = ret[1];
    } catch {
        return {message: "Error: Unable to extract features from data source!"};
    }
    
    try{
        plots = plotFeatures(features, number_of_observations_with_missing_data);
    } catch {
        return {message: "Error: Unable to draw plots!"};
    }

    //console.log(plots);
    return {message: "Success!", plots: plots, data: data};
};

export default getPlots;