import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React, { Component } from 'react';

import { Bar, Scatter } from 'react-chartjs-2';

import shortid from 'shortid';

import Plot from 'react-plotly.js';


export class Chartzone extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.fileInfo !== nextProps.fileInfo;
    }

    datasetKeyProvider = () => shortid.generate();

    render() {
        const plots = this.props.fileInfo.plots;
        let canvasMap = new Map();
        if (plots) {
            for (const category in plots) {
                for (let i = 0; i < plots[category].length; ++i) {
                    try {
                        const plotData = plots[category][i];
                        let canvasType;
                        if (plotData.library === 'chartjs') {
                            switch (plotData.type) {
                                case 'bar':
                                    canvasType = <Bar datasetKeyProvider={this.datasetKeyProvider} data={plotData.data} options={plotData.options} />;
                                    break;
                                case 'scatter':
                                    canvasType = <Scatter datasetKeyProvider={this.datasetKeyProvider} data={plotData.data} options={plotData.options} />;
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (plotData.library === 'plotly') {
                            canvasType = <Plot className='w-100 h-100' data={plotData.data} layout={plotData.layout} useResizeHandler={true} />;
                        }
                        else {
                            continue;
                        }
                        if (!canvasMap.get(plotData.chart_category)) canvasMap.set(plotData.chart_category, []);
                        canvasMap.get(plotData.chart_category).push(<Grid style={{ minHeight: '400px' }} item xs={12} lg={6} xl={4} key={shortid.generate()}>{canvasType}</Grid>);
                    }
                    catch {
                        console.error('Error in plotting');
                    }
                }
            };
        }
        const chartRenderList = [];
        // Sort map by key
        canvasMap = new Map([...canvasMap.entries()].sort());
        for (const [key, value] of canvasMap) {
            chartRenderList.push(
                <ExpansionPanel
                    key={shortid.generate()}
                    TransitionProps={{ mountOnEnter: true }}
                    className='mb-2'
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        {canvasTypeName[key]}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            {value}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>,
            );
        };
        chartRenderList.push(<div className='mt-5'></div>);
        return (
            <div className='p-3 m-3 overflow-auto' style={{ height: '90vh' }}>
                {chartRenderList}
            </div>
        );
    }
}

export default Chartzone;

const canvasTypeName = {
    box: 'Box plots',
    correlation: 'Correlation matrices',
    density: 'Density plots',
    frequency: '1D Frequency plots',
    frequency2D: '2D Frequency plots',
    histogram: 'Histograms',
    scatter: 'Scatter plots',
    swarm: 'Swarm plots',
};


// const dataTitle = "Histogram of data";
// const dataValues = [12, 19, 3, 5, 6, 7, 4, 10, 22, 3, 15];
// const dataLabels = ["0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20"];

// const data = {
//     type: 'bar',
//     data: {
//         labels: dataLabels,
//         datasets: [{
//             label: "Value",
//             data: dataValues,
//             backgroundColor: '#e9041e',
//             borderWidth: 2
//         }]
//     },
//     options: {
//         title: {
//             display: true,
//             text: dataTitle
//         },
//         legend: {
//             display: false
//         },
//         scales: {
//             xAxes: [{
//                 display: false,
//                 barPercentage: 1.25,
//                 ticks: {
//                     max: 3,
//                 }
//             }, {
//                 display: true,
//                 ticks: {
//                     autoSkip: false,
//                     max: 4,
//                 }
//             }],
//             yAxes: [{
//                 ticks: {
//                     beginAtZero:true
//                 }
//             }]
//         }
//     }
// }