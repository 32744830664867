import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import React from 'react';

import { SnackbarProvider } from 'notistack';

import './App.css';

import Dashboard from './components/Layout/Dashboard';


export class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			darkmode: localStorage.getItem('darkmode') === 'true' ? true : false,
		};
		this.theme = this.getTheme(this.state.darkmode);
	}

	getTheme = (darkmode) => {
		return createMuiTheme({
			palette: {
				type: darkmode ? 'dark' : 'light',
				primary: {
					light: '#ff5648',
					main: '#e9041e',
					dark: '#ae0000',
					contrastText: '#fff',
				},
				secondary: {
					light: '#484848',
					main: '#212121',
					dark: '#000000',
					contrastText: '#fff',
				},
			},
		});
	}
	changeTheme = (event) => {
		const newTheme = !this.state.darkmode;
		this.theme = this.getTheme(newTheme);
		this.setState({ darkmode: newTheme });
		localStorage.setItem('darkmode', newTheme);
	}

	render() {
		return (
			<MuiThemeProvider theme={this.theme}>
				<SnackbarProvider
					maxSnack={1}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<Dashboard darkmode={this.state.darkmode} changeTheme={this.changeTheme} />
				</SnackbarProvider>
			</MuiThemeProvider>
		);
	}
}

export default App;
