import { bin_width } from './statistics';
import { mergeFeatures, getColors} from './miscellaneous';
import { MAXIMUM_BAR_COUNT, MAXIMUM_MULTI_BAR_COUNT, MAXIMUM_WIDTH_SWARM_PLOT, FONT_COLOR, POPULAR_VALUES_COUNT } from './constant';

export let plotSwarm = function (feature1, feature2){
    let dataTitle = "Swarm plot of 2 features";
    if (feature1.title !== null && feature2.title !== null) 
        dataTitle = "Swarm plot of " + feature1.title + " vs " + feature2.title;

    let mergedData = mergeFeatures([feature1, feature2]);
    
    let datasetLabels = [];

    // Get unique values
    for (let i = 0; i < mergedData.data.length; i++)
        if (!datasetLabels.includes(mergedData.data[i][1])) {
            datasetLabels.push(mergedData.data[i][1]);
            if (datasetLabels.length > MAXIMUM_BAR_COUNT) return null;
        } 
    
    datasetLabels.sort();

    // Generate colors
    let colors = getColors(datasetLabels.length);
    
    // Generate dataset
    let dataset = [];
    for (let i = 0; i < datasetLabels.length; i++) {
        dataset.push({label: datasetLabels[i],
                    backgroundColor: colors[i],
                    data: []});
    }
    for (let i = 0; i < mergedData.data.length; i++)
        dataset[datasetLabels.indexOf(mergedData.data[i][1])].data.push(
            {
                x: datasetLabels.indexOf(mergedData.data[i][1]) + 1 + Math.random() * MAXIMUM_WIDTH_SWARM_PLOT - MAXIMUM_WIDTH_SWARM_PLOT / 2, 
                y: mergedData.data[i][2]
            }
        );

    return {
        type: "scatter",
        library: "chartjs",
        chart_category: "swarm",
        recommended: false,
        data: {
            datasets: dataset
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: true,
                fontColor: FONT_COLOR,
                text: dataTitle
            },
            legend: {
                labels:{
                    fontColor: FONT_COLOR
                } 
            },
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: feature1.title,
                        fontColor: FONT_COLOR
                    }, 
                    ticks: {
                        display: false,
                        suggestedMin: 0,
                        suggestedMax: datasetLabels.length + 1,
                        fontColor: FONT_COLOR
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: feature2.title,
                        fontColor: FONT_COLOR
                    },
                    ticks: {
                        fontColor: FONT_COLOR
                    }
                }]
              }    
        }
    }
}

export let plotFrequency2D = function (feature1, feature2){
    let dataTitle = "Frequency of 2 features";
    if (feature1.title !== null && feature2.title !== null) 
        dataTitle = "Frequency of " + feature1.title + " vs " + feature2.title;

    let mergedData = mergeFeatures([feature1, feature2]);
    
    let dataLabels = [];
    let datasetLabels = [];

    // Get unique values
    for (let i = 0; i < mergedData.data.length; i++)
        if (!dataLabels.includes(mergedData.data[i][1])) {
            dataLabels.push(mergedData.data[i][1]);
            if (dataLabels.length > MAXIMUM_BAR_COUNT) return null;
        } 
    for (let i = 0; i < mergedData.data.length; i++)
        if (!datasetLabels.includes(mergedData.data[i][2])) {
            datasetLabels.push(mergedData.data[i][2]);
            if (datasetLabels.length * dataLabels.length > MAXIMUM_MULTI_BAR_COUNT) return null;
        } 

    dataLabels.sort();
    datasetLabels.sort();

    // Generate colors
    let colors = getColors(datasetLabels.length);
    
    // Generate dataset
    let dataset = [];
    for (let i = 0; i < datasetLabels.length; i++) {
        dataset.push({label: datasetLabels[i],
                    backgroundColor: colors[i],
                    borderWidth: 2,
                    data: []});
        for (let j = 0; j < dataLabels.length; j++) dataset[i].data.push(0);
    }
    for (let i = 0; i < mergedData.data.length; i++)
        dataset[datasetLabels.indexOf(mergedData.data[i][2])].data[dataLabels.indexOf(mergedData.data[i][1])] += 1;

    return {
        type: 'bar',
        library: "chartjs",
        chart_category: "frequency2D",
        recommended: false,
        data: {
            labels: dataLabels,
            datasets: dataset
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: true,
                fontColor: FONT_COLOR,
                text: dataTitle
            },
            legend: {
                labels:{
                    fontColor: FONT_COLOR
                } 
            },
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: feature1.title,
                        fontColor: FONT_COLOR
                    }, 
                    ticks: {
                        fontColor: FONT_COLOR
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: feature2.title,
                        fontColor: FONT_COLOR
                    }, 
                    ticks: {
                        fontColor: FONT_COLOR,
                        beginAtZero: true
                    }
                }]
            }
        }
    }
}

export let plotScatter2D = function (feature1, feature2){
    let dataTitle = "Scatter plot of 2 features";
    if (feature1.title !== null && feature2.title !== null) 
        dataTitle = "Scatter plot of " + feature1.title + " vs " + feature2.title;

    let dataValues = [];
    let mergedData = mergeFeatures([feature1, feature2]);
    for (let i = 0; i < mergedData.data.length; i++)
        dataValues.push({x: mergedData.data[i][1], y: mergedData.data[i][2]});

    return {
        type: "scatter",
        library: "chartjs",
        chart_category: "scatter",
        recommended: false,
        data: {
            datasets: [{
                label: 'Value',
                backgroundColor: getColors()[0],
                data: dataValues
            }]
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: true,
                fontColor: FONT_COLOR,
                text: dataTitle
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: FONT_COLOR
                    },
                    scaleLabel: {
                        display: true,
                        labelString: feature1.title,
                        fontColor: FONT_COLOR
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: FONT_COLOR
                    },
                    scaleLabel: {
                      display: true,
                      labelString: feature2.title,
                      fontColor: FONT_COLOR
                    }
                }]
              }    
        }
    }
}

export let plotFrequency = function (feature) {
    let dataTitle = "Frequency of " + (feature.title === null ? "feature" : feature.title);
    let dataValues = [];
    let dataLabels = feature.categories;

    let popularValues = [];
    for (let i = 0; i < Math.min(POPULAR_VALUES_COUNT, feature.categories.length); i++){
        popularValues.push({
            category: feature.categories[feature.categoriesIndex[i]],
            count: feature.categoriesCount[feature.categoriesIndex[i]],
            percentage: feature.categoriesCount[feature.categoriesIndex[i]] / feature.totalCount * 100
        });
    }

    if (dataLabels.length > MAXIMUM_BAR_COUNT) return {
        title: feature.title === null ? "feature" : feature.title,
        type: null,
        library: null,
        chart_category: "information feature",
        recommended: false,
        number_of_numerical_values: feature.numericalCount,
        percentage_of_numerical_values: feature.numericalCount / feature.totalCount * 100,
        number_of_categorical_values: feature.categoricalCount,
        percentage_of_categorical_values: feature.categoricalCount / feature.totalCount * 100,
        number_of_missing_values: feature.missingCount,
        percentage_of_missing_values: feature.missingCount / feature.totalCount * 100,
        number_of_categories: feature.categories.length,
        categories: feature.categories,
        popular_values: popularValues
    };
    
    // Initialize length of dataValues
    for (let i = 0; i < dataLabels.length; i++) dataValues.push(0);

    // Get unique values
    for (let i = 0; i < feature.data.length; i++){
        dataValues[dataLabels.indexOf(feature.data[i][1])] += 1;
    }

    return {
        title: feature.title === null ? "feature" : feature.title,
        type: 'bar',
        library: "chartjs",
        chart_category: "frequency",
        recommended: false,
        data: {
            labels: dataLabels,
            datasets: [{
                label: "Frequency",
                data: dataValues,
                backgroundColor: getColors()[0],
                borderWidth: 2
            }]
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: true,
                fontColor: FONT_COLOR,
                text: dataTitle
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: FONT_COLOR
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: FONT_COLOR,
                        beginAtZero: true
                    }
                }]
            }
        },
        number_of_numerical_values: feature.numericalCount,
        percentage_of_numerical_values: feature.numericalCount / feature.totalCount * 100,
        number_of_categorical_values: feature.categoricalCount,
        percentage_of_categorical_values: feature.categoricalCount / feature.totalCount * 100,
        number_of_missing_values: feature.missingCount,
        percentage_of_missing_values: feature.missingCount / feature.totalCount * 100,
        number_of_categories: feature.categories.length,
        categories: feature.categories,
        popular_values: popularValues
    }
}

export let plotHistogramChartJS = function (feature) {
    let dataTitle = "Histogram of " + (feature.title === null ? "feature" : feature.title);
    let dataValues = [];
    let dataLabels = [];

    let valueArray = [];
    for (let i = 0; i < feature.data.length; i++) {
        valueArray.push(feature.data[i][1]);
    }
    let minValue = Math.min(...valueArray);
    let maxValue = Math.max(...valueArray);
    let binWidth = Math.max(bin_width(valueArray), (maxValue - minValue) / MAXIMUM_BAR_COUNT);
    for (let i = minValue; i <= maxValue; i += binWidth) {
        dataLabels.push(i.toFixed(2));
        dataValues.push(0);
    }
    for (let i = 0; i < valueArray.length; i++)
        for (let j = dataLabels.length - 1; j >= 0; j--)
            if (valueArray[i] >= dataLabels[j]) {
                dataValues[j] += 1;
                break;
            }

    return {
        title: feature.title === null ? "feature" : feature.title,
        type: 'bar',
        library: "chartjs",
        chart_category: "histogram",
        recommended: false,
        data: {
            labels: dataLabels,
            datasets: [{
                label: "Value",
                data: dataValues,
                backgroundColor: getColors()[0],
                borderWidth: 2
            }]
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: true,
                fontColor: FONT_COLOR,
                text: dataTitle
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: false,
                    barPercentage: 1.25,
                    ticks: {
                        max: 3,
                    }
                }, {
                    display: true,
                    ticks: {
                        fontColor: FONT_COLOR,
                        autoSkip: false,
                        max: 4,
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: FONT_COLOR,
                        beginAtZero: true
                    }
                }]
            },
            tooltips: {
                enabled: false
            }
        },
        number_of_numerical_values: feature.numericalCount,
        percentage_of_numerical_values: feature.numericalCount / feature.totalCount * 100,
        number_of_categorical_values: feature.categoricalCount,
        percentage_of_categorical_values: feature.categoricalCount / feature.totalCount * 100,
        number_of_missing_values: feature.missingCount,
        percentage_of_missing_values: feature.missingCount / feature.totalCount * 100,
        min: feature.minValue,
        first_quartile: feature.q1,
        median: feature.median,
        third_quartile: feature.q3,
        max: feature.maxValue,
        mean: feature.mean,
        stddev: feature.standardDeviation
    }
}

