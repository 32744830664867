import React from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

// A simple component that shows the pathname of the current location
class ShowTheLocation extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };


    render() {
        // const { match, location, history } = this.props;
        // const { location } = this.props;
        // this.props.setLocation(location.pathname);
        // return <div>You are now at {location.pathname}</div>;
        return <></>;
    }
}

// Create a new component that is 'connected' (to borrow redux
// terminology) to the router.
const ShowTheLocationWithRouter = withRouter(ShowTheLocation);

export default ShowTheLocationWithRouter;