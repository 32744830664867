import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';

import React, { PureComponent } from 'react';

export class Logo extends PureComponent {
    render() {
        return (
            <Button
                href='/'
                variant='outlined'
                color='default'
                size='large'
                style={{ border: 'none' }}
                startIcon={<AssessmentIcon />}
            >
                DAVIZ
			</Button>
        );
    }
}

export default Logo;
