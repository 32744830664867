import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GetApp from '@material-ui/icons/GetApp';
import Publish from '@material-ui/icons/Publish';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import React from 'react';

import XLSX from 'xlsx';

import Container from 'react-bootstrap/Container';

import { withSnackbar } from 'notistack';

import { getPlots } from './chart/data';

class Dropzone extends React.Component {
    constructor(props) {
        super(props);
        this.handleFile = this.handleFile.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.loadTestDataset = this.loadTestDataset.bind(this);
        this.handleSelectTestData = this.handleSelectTestData.bind(this);
        this.state = { selectedTestData : 'Titanic' };
    };

    handleFile(file) {
        if (file.size <= 1024 * 1024 * 5) {
            this.props.setLoading(true);
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (e) => {
                try {
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const rawdata = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    this.updateData(rawdata, file.name, wsname);
                }
                catch (error) {
                    console.error(error);
                    this.props.enqueueSnackbar('Error occurs when reading this file', {
                        variant: 'error',
                    });
                }
                this.props.setLoading(false);
            };
            if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        }
        else {
            this.props.enqueueSnackbar('File\'s size is too large ( > 5MB)', {
                variant: 'error',
            });
        }
    };
    updateData(rawdata, fileName, wsname) {
        /* Clean data */
        const processedData = getPlots(rawdata);
        let data = [];
        const plots = {};
        if (processedData.message === 'Success!') {
            data = processedData.data.data;
            for (const plot of processedData.plots) {
                if (!plots[plot.chart_category]) plots[plot.chart_category] = [];
                plots[plot.chart_category].push(plot);
            }
        }
        else {
            throw new Error('Failed to plot');
        }
        /* Update state */
        this.props.setFileInfo({ fileName: fileName, sheetName: wsname, data: data, plots: plots });
    }
    exportFile() {
        /* convert state to workbook */
        const ws = XLSX.utils.aoa_to_sheet(this.props.fileInfo.data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.props.fileInfo.sheetName);
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, this.props.fileInfo.fileName);
    };
    loadTestDataset() {
        this.props.setLoading(true);
        fetch(`/datasets/${this.state.selectedTestData}.txt`).then(async (response) => {
            if (response.ok) {
                const rawdata = JSON.parse(await response.text());
                this.updateData(rawdata, `${this.state.selectedTestData}.csv`, this.state.selectedTestData);
                this.props.enqueueSnackbar('Table loaded successfully', {
                    variant: 'success',
                });
            }
            else {
                this.props.enqueueSnackbar('Error in fetching test data', {
                    variant: 'error',
                });
            }
            this.props.setLoading(false);
        });
    }

    handleSelectTestData(e) {
        this.setState({
            selectedTestData: e.target.value,
        });
    }

    render() {
        return (
            <DragDropFile handleFile={this.handleFile}>
                <Paper>
                    <Container>
                        <Grid container>
                            <Grid item xs={4} className='d-flex justify-content-center'>
                                <DataInput handleFile={this.handleFile} />
                            </Grid>
                            <Grid item xs={4} className='d-flex justify-content-center'>
                                <Select
                                    value={this.state.selectedTestData}
                                    onChange={this.handleSelectTestData}
                                >
                                    <InputLabel className='m-2'>Test dataset</InputLabel>
                                    <MenuItem value={'Titanic'}>Titanic</MenuItem>
                                    <MenuItem value={'Diamonds'}>Diamonds</MenuItem>
                                </Select>

                                <Button variant='outlined' color='primary' className='m-3' onClick={this.loadTestDataset}>
                                    Load test datasets
                                </Button>
                            </Grid>
                            <Grid item xs={4} className='d-flex justify-content-center'>
                                <Button variant='contained' color='primary' size='large' className='m-3' disabled={!this.props.fileInfo.data.length} onClick={this.exportFile}>
                                    Export <GetApp className='ml-2' />
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </DragDropFile >
        );
    };
};

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    };
    suppress(evt) { evt.stopPropagation(); evt.preventDefault(); };
    onDrop(evt) {
        evt.stopPropagation(); evt.preventDefault();
        const files = evt.dataTransfer.files;
        if (files && files[0]) this.props.handleFile(files[0]);
    };
    render() {
        return (
            <div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
                {this.props.children}
            </div>
        );
    };
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    };
    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.props.handleFile(files[0]);
    };
    render() {
        return (
            <form className='w-100'>
                <div>
                    <input type='file' className='form-control' style={{ display: 'none' }} id='file' accept={SheetJSFT} onChange={this.handleChange} />
                    <label htmlFor='file' className='d-flex justify-content-center'>
                        <Button variant='contained' color='secondary' size='large' component='span' className='m-3'>
                            Upload <Publish className='ml-2' />
                        </Button>
                    </label>
                </div>
            </form>
        );
    };
}

/* list of supported file types */
const SheetJSFT = [
    'xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm',
].map((x) => { return '.' + x; }).join(',');

export default withSnackbar(Dropzone);