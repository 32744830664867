export let getColors = function(count){
    // Initial color scheme
    let colorScheme = ["#011f4baa", "#03396caa", "#005b96aa", "#6497b1aa", "#b3cde0aa", "#3385c6aa", "#4279a3aa", "#476c8aaa", "#49657baa", "#7f8e9eaa", "#bdeaeeaa", "#76b4bdaa", "#58668baa"];
    // Random shuffle
    for (let i = 0; i < colorScheme.length; i++){
        let u = Math.floor(Math.random()*colorScheme.length);
        let v = Math.floor(Math.random()*colorScheme.length);
        let tmp = colorScheme[u];
        colorScheme[u] = colorScheme[v];
        colorScheme[v] = tmp;
    }
    // Randomize additional colors
    while (colorScheme.length < count){
        colorScheme.push('rgba(' + 
                        Math.floor(Math.random()*256).toString() + ',' + 
                        Math.floor(Math.random()*256).toString() + ',' + 
                        Math.floor(Math.random()*256).toString() + ',0.6)');
    }
    // Remove unnecessary colors
    while (colorScheme.length > count) colorScheme.pop();
    
    return colorScheme;
}

export let mergeFeatures = function (features){
    // If there is only one feature then no need to merge
    if (features.length === 1) return features[0];
    
    // Initialize variables
    let res = {data : []};

    let j = 0, k = 0;
    let last = [], curr = [];
    for (let i = 0; i < features.length; i++){
        last.push(0);
        curr.push(-1);
    }

    for (let i = 0; i < features[0].data.length; i++){
        for (j = 1; j < features.length; j++){
            curr[j] = -1;
            for (k = last[j]; k < features[j].data.length; k++)
                if (features[0].data[i][0] === features[j].data[k][0]){
                    curr[j] = k;
                    last[j] = k;
                    break;
                }
            if (curr[j] === -1) break;
        }
        // Check if a match is found
        curr[0] = 0;
        for (j = 1; j < features.length; j++)
            if (curr[j] === -1){
                curr[0] = -1;
                break;
            }
        // Push the match in the result
        if (curr[0] === 0){
            res.data.push([]);
            res.data[res.data.length - 1].push(features[0].data[i][0]);
            res.data[res.data.length - 1].push(features[0].data[i][1]);
            for (j = 1; j < features.length; j++) 
                res.data[res.data.length - 1].push(features[j].data[curr[j]][1]);
                
        }
    }
    return res;
}

export let deepCopy = function (rawdata, data) {
    for (let i = 0; i < rawdata.length; i++) {
        data.push([]);
        for (let j = 0; j < rawdata[i].length; j++) data[i].push(rawdata[i][j]);
    }
}

export let getOverview = function (features, number_of_observations_with_missing_data) {
    let featuresCount = features.length;
    let observationCount = features[0].numericalCount + features[0].categoricalCount + features[0].missingCount;
    let numercialFeatures = [];
    let categoricalFeatures = [];
    let mixedFeatures = [];
    let missingFeatures = [];
    let missingCells = 0;
    for (let i = 0; i < features.length; i++){
        if (features[i].numericalCount > 0 && features[i].categoricalCount > 0)
            mixedFeatures.push(features[i].title);
        else if (features[i].type === "Numerical")
            numercialFeatures.push(features[i].title);
        else
            categoricalFeatures.push(features[i].title);
        if (features[i].missingCount > 0) missingFeatures.push(features[i].title);
        missingCells += features[i].missingCount;
    }
    return {
        type: null,
        library: null,
        chart_category: "overview",
        recommended: false,
        number_of_features: featuresCount,
        number_of_numerical_features: numercialFeatures.length, 
        numercial_features_list: numercialFeatures,
        number_of_categorical_features: categoricalFeatures.length,
        categorical_features_list: categoricalFeatures,
        number_of_mixed_features: mixedFeatures.length,
        mixed_features_list: mixedFeatures,
        number_of_observations: observationCount, 
        number_of_missing_cells: missingCells,
        percentage_of_missing_cells: missingCells / (featuresCount * observationCount) * 100,
        number_of_features_with_missing_data: missingFeatures.length,
        missing_features_list: missingFeatures,
        number_of_observations_with_missing_data: number_of_observations_with_missing_data,
        percentage_of_observations_with_missing_data: number_of_observations_with_missing_data / observationCount * 100
    };
}
